import React, { useState, useContext, FC, ReactNode } from "react";
import data from "../data.json";

//1. Set IAppProviderProps
interface IAppProviderProps {
  children: ReactNode;
}
//2. Set interface of Type for state (for Object or Array)

//3. Set interface or contextValue to use in global
interface IContextValue {
  content: any;
  changeLanguage: (lang: string) => void;
}

//4. Set AppContext
const AppContext = React.createContext<IContextValue | undefined>(undefined);

//5. Set Approvider
const AppProvider: FC<IAppProviderProps> = ({ children }) => {
  //6. Set State
  const [content, setContent] = useState<any>(data.en); //เริ่มต้นด้วยภาษาอังกฤษ

  const changeLanguage = (lang: string) => {
    if (lang === "en") {
      setContent(data.en);
    }
    if (lang === "th") {
      setContent(data.th);
    }
  };
  //Set Value
  return (
    <AppContext.Provider
      value={{
        content,
        changeLanguage,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a AppProvider");
  }
  return context;
};

export { AppContext, AppProvider };

//7. ไปครอบ App Provider ใน index.tsx
