import React from "react";
import "../scss/Footer.scss";

const Footer = () => {
  return (
    <div className='footer-container'>
      Copyright © 2023 CMG Consulting Company Limited
    </div>
  );
};

export default Footer;
