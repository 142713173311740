import React from "react";

function Map() {
  const mapUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.5988683571713!2d100.57214817348712!3d13.773477185516377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29e80b03fffff%3A0x25ca18ca786fd2ce!2z4Lia4Lij4Li04Lip4LmN4LiXIOC4i-C4teC5gOC4reC5h-C4oeC4iOC4tSDguITguK3guJnguIvguLHguKXguJXguLTguYnguIcg4LiI4Liz4LiB4Lix4LiU!5e0!3m2!1sen!2sus!4v1685632066547!5m2!1sen!2sus";
  return (
    <iframe
      src={mapUrl}
      width='100%'
      height='100%'
      style={{ border: 0 }}
      allowFullScreen
      loading='lazy'
      referrerPolicy='no-referrer-when-downgrade'
      title='Google Maps Embed'></iframe>
  );
}

export default Map;
