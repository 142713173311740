import React from "react";
import "./App.css";
import {
  Nav,
  Cover,
  Mission,
  Service,
  Contact,
  Clients,
  Footer,
} from "./components";

function App() {
  return (
    <div className='App'>
      <Nav />
      <Cover />
      <Mission />
      <Service />
      <Clients />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
