import React from "react";
import "../scss/Service.scss";
import { useGlobalContext } from "../context/context";
import { ReactComponent as CheckIcon } from "../assets/circle-check-solid.svg";

const Service = () => {
  const { content } = useGlobalContext();
  return (
    <div className='service-container' id='ourservices'>
      <div className='h1-nomargin'>{content.service.head1}</div>
      <div className='underline' style={{ width: "150px" }}></div>
      <div className='service-box'>
        <div className='service-innerbox'>
          <div className='service-innerbox-1'>
            <div className='service-innerbox-1-L'>
              <h1 style={{ color: "white" }}>{content.service.box1.heading}</h1>
            </div>
            <div className='service-innerbox-1-R'>
              <div>
                {content.service.box1.description.map(
                  (dat: string, i: number) => {
                    return (
                      <div className='service-text' key={i}>
                        <p>• {dat}</p>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className='service-innerbox-2'>
            <div className='service-innerbox-2-L'>
              <div>
                {content.service.box2.description.map(
                  (dat: string, i: number) => {
                    return (
                      <div className='service-text' key={i}>
                        <p>• {dat}</p>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className='service-innerbox-2-R'>
              <h1 style={{ color: "white" }}>{content.service.box2.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='h1-nomargin'>{content.service.research.heading}</div>
      <div className='underline' style={{ width: "150px" }}></div>
      <div className='service-flex-wrap'>
        {" "}
        {content.service.research.description.map((dat: string, i: number) => {
          return (
            <div className='service-text' key={i}>
              <CheckIcon style={{ height: "25px", fill: "#3AE388" }} />
              <p>{dat}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Service;
