import React, { useState, useEffect } from "react";
import "../scss/Nav.scss";
import logo from "../assets/CMG_LOGO.png";
import thlogo from "../assets/thailand.png";
import uklogo from "../assets/united-kingdom.png";

import { useGlobalContext } from "../context/context";
import { FaBars } from "react-icons/fa";

const Nav = () => {
  const { content, changeLanguage } = useGlobalContext();
  const [navDisplay, setnavDisplay] = useState<string>("none");

  useEffect(() => {
    const handleResize = () => {
      let windowWidth: number = window.innerWidth;
      if (windowWidth >= 785) setnavDisplay("none");
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleButton = () => {
    if (navDisplay === "flex") {
      setnavDisplay("none");
    } else {
      setnavDisplay("flex");
    }
  };

  return (
    <div>
      <nav className='nav-mobile'>
        <img className='nav-logo' src={logo} alt='logo'></img>
        <div
          className='nav-button align-right'
          onClick={() => {
            handleButton();
          }}>
          <FaBars />
        </div>
      </nav>
      <>
        <div className='nav-mobile-box' style={{ display: `${navDisplay}` }}>
          {content.navbar.map((dat: any, i: number) => {
            const { text, href } = dat;
            return (
              <a key={i} href={`/#${href}`}>
                {text}
              </a>
            );
          })}

          <div className='underline-inbox'></div>
          <div className='flag-logo-box'>
            <img
              className='flag-logo'
              src={thlogo}
              alt='th-logo'
              onClick={() => {
                changeLanguage("th");
              }}
            />
            <img
              className='flag-logo'
              src={uklogo}
              alt='uk-logo'
              onClick={() => {
                changeLanguage("en");
              }}
            />
          </div>
        </div>
      </>

      <nav className='nav-container'>
        <div className='nav-center'>
          <div className='nav-header'>
            <div className='nav-flexbox'>
              <div className='logo-box'>
                <img className='nav-logo' src={logo} alt='logo'></img>
              </div>
              <div className='links'>
                {content.navbar.map((dat: any, i: number) => {
                  const { text, href } = dat;
                  return (
                    <a key={i} href={`/#${href}`}>
                      {text}
                    </a>
                  );
                })}
              </div>

              <div className='flag-logo-box'>
                <img
                  className='flag-logo'
                  src={thlogo}
                  alt='th-logo'
                  onClick={() => {
                    changeLanguage("th");
                  }}
                />
                <img
                  className='flag-logo'
                  src={uklogo}
                  alt='uk-logo'
                  onClick={() => {
                    changeLanguage("en");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
