import React from "react";
import "../scss/Clients.scss";
import { useGlobalContext } from "../context/context";

const clientLogoNames = [
  "beauty.png",
  "itd_logo-01.png",
  "JMART.png",
  "johnson-johnson-logo-png-transparent.png",
  "King_Power_logo.png",
  "KSL.png",
  "Mali.png",
  "Moccona-logo-EBE145B094-seeklogo.com.png",
  "paragon.png",
  "The_Mall_Group_logo.png",
  "ttcl-public-company-limited-logo-vector.png",
];

function Clients() {
  const { content } = useGlobalContext();
  return (
    <div className='clients-container' id='ourclients'>
      <div className='h1-nomargin'>{content.clients.heading}</div>
      <div className='underline' style={{ width: "100px" }}></div>
      <p style={{ textAlign: "center" }}>{content.clients.description}</p>
      <div className='clients-logo-area'>
        {clientLogoNames.map((logoName, index) => (
          <img
            className='clients-logo'
            key={index}
            src={`${process.env.PUBLIC_URL}/clientslogo/${logoName}`}
            alt={`Client Logo ${index + 1}`}
          />
        ))}
      </div>
      <h1> </h1>
    </div>
  );
}

export default Clients;
