import React from "react";
import "../scss/Cover.scss";
import background from "../assets/Background1.png";
import { useGlobalContext } from "../context/context";
const Cover = () => {
  const { content } = useGlobalContext();
  return (
    <>
      <div className='cover-image-box'>
        <img src={background} alt='Background1.png' />
      </div>
      <div className='cover-container' id='aboutus'>
        <div className='outer-box'>
          <div className='inner-box'>
            <h1>{content.cover.heading}</h1>
            <p>{content.cover.description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cover;
