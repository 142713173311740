import React from "react";
import "../scss/Mission.scss";
import missionImg from "../assets/MissionImg.png";
import playIcon from "../assets/play-solid.svg";
import { useGlobalContext } from "../context/context";

const Mission = () => {
  const { content } = useGlobalContext();
  console.log(content.mission);
  return (
    <div className='mission-container'>
      <div className='mission-box-1'>
        <h1>{content.mission.heading}</h1>
        {content.mission.description.map((dat: string, i: number) => {
          return (
            <div className='mission-text' key={i}>
              <img src={playIcon} style={{ height: "16px" }} alt='' />
              <p>{dat}</p>
            </div>
          );
        })}
      </div>
      <div className='mission-box-2'>
        <img src={missionImg} alt='' />
      </div>
    </div>
  );
};

export default Mission;
