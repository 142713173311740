import React from "react";
import "../scss/Contact.scss";
import { ReactComponent as LocationIcon } from "../assets/location-dot-solid.svg";
import { ReactComponent as PhoneIcon } from "../assets/phone-solid.svg";
import { ReactComponent as WorldIcon } from "../assets/earth-americas-solid.svg";
import { ReactComponent as EmailIcon } from "../assets/envelope-solid.svg";
import { useGlobalContext } from "../context/context";
import Map from "./Map";

const Contact = () => {
  const { content } = useGlobalContext();
  return (
    <div className='contact-container' id='contactus'>
      <div className='contact-L'>
        <Map></Map>
      </div>
      <div className='contact-R'>
        <h1 style={{ color: "white" }}>{content.contact.heading}</h1>
        <div className='contact-box'>
          <div className='contact-head'>
            <LocationIcon style={{ fill: "white", height: "25px" }} />
            <p>{content.contact.location.heading}</p>
          </div>
          <p>{content.contact.location.description}</p>
        </div>
        <div className='contact-box'>
          <div className='contact-head'>
            <PhoneIcon style={{ fill: "white", height: "25px" }} />
            <p>{content.contact.phone.heading}</p>
          </div>
          <p>{content.contact.phone.description}</p>
        </div>
        <div className='contact-box'>
          <div className='contact-head'>
            <WorldIcon style={{ fill: "white", height: "25px" }} />
            <p>{content.contact.website.heading}</p>
          </div>
          <p>{content.contact.website.description}</p>
        </div>
        <div className='contact-box'>
          <div className='contact-head'>
            <EmailIcon style={{ fill: "white", height: "25px" }} />
            <p>{content.contact.email.heading}</p>
          </div>
          <p>{content.contact.email.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
